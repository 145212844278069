<template>
    <div v-if="!! bot"
         id="opn-ai-chat"
         class="opn-ai-chat"
         :style="botStyle"
         :class="[ ...position, !! theme ? `theme-${theme}` : null ]">
      <chat-window :expanded="expanded"
                   :theme="theme"
                   :chat-user="chatUser"
      />

      <div @click="expanded = ! expanded"
           :class="{ expanded }"
           class="toggle">
        <i v-if="! expanded" class="far fa-comment fa-fw" />
        <i v-else class="far fa-chevron-down fa-fw" />
      </div>
    </div>
</template>

<style lang="scss" scoped>
.opn-ai-chat {
  position: fixed;
  z-index: 2147483647;

  display: flex;
  flex-direction: column;
  gap: 10px;

  pointer-events: none;

  // Theme variables

  --primary-h: 220;
  --primary-s: 100%;
  --primary-l: 50%;
  --primary: hsl(var(--primary-h), var(--primary-s), var(--primary-l));

  --dark-h: 0;
  --dark-s: 0%;
  --dark-l: 0%;
  --dark: hsl(var(--dark-h), var(--dark-s), var(--dark-l));

  --light-h: 0;
  --light-s: 0%;
  --light-l: 100%;
  --light: hsl(var(--light-h), var(--light-s), var(--light-l));

  &.theme-red {
    --primary-h: 0;
    --primary-s: 100%;
    --primary-l: 50%;
    --primary: hsl(var(--primary-h), var(--primary-s), var(--primary-l));
  }

  &.theme-orange {
    --primary-h: 30;
    --primary-s: 100%;
    --primary-l: 50%;
    --primary: hsl(var(--primary-h), var(--primary-s), var(--primary-l));
  }

  &.theme-green {
    --primary-h: 150;
    --primary-s: 100%;
    --primary-l: 35%;
    --primary: hsl(var(--primary-h), var(--primary-s), var(--primary-l));
  }

  &.theme-purple {
    --primary-h: 255;
    --primary-s: 100%;
    --primary-l: 50%;
    --primary: hsl(var(--primary-h), var(--primary-s), var(--primary-l));
  }

  // Positions

  &.chat-bot-right {
    align-items: flex-end;
    right: 25px;
  }

  &.chat-bot-bottom {
    align-items: flex-end;
    bottom: 25px;
  }

  &.chat-bot-left {
    align-items: flex-start;
    left: 25px;
  }

  &.chat-bot-top {
    flex-direction: column-reverse;
    top: 25px;
  }

  > div.toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    font-size: 26px;
    border-radius: 50%;
    color: var(--light);
    background-image: linear-gradient(to bottom right, var(--primary), hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) - 15%)));
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    background-color: var(--primary);
    transition: all .2s ease-in-out;

    cursor: pointer;
    user-select: none;

    pointer-events: all;

    &:hover {
      transform: scale(1.2);
    }

    &.expanded {
      transform: scale(1) ! important;
      width: 50px;
      height: 50px;
    }
  }
}
</style>

<script>
import ChatWindow from '@/app/chat/ChatWindow'
import Vue from 'vue'
import Client from './app/chat/Client'
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import axios from 'axios'
import Cookies, {COOKIE_ID} from './app/chat/Cookies'

import options from '../options'

const FALLBACK_THEME = 'blue'

export default {
  name: 'App',

  data: () => ({
    expanded: false,
    chatUser: null
  }),

  computed: {
    position() {
      const position = (this.bot || {}).position || null

      if (! position) {
        return ['chat-bot-bottom', 'chat-bot-right']
      }

      return String(position).split('_').map(item => `chat-bot-${item}`)
    },

    bot() {
      return (this.chatUser || {}).bot || null
    },

    theme() {
      const theme = (this.bot || {}).theme || null

      if (theme === null || theme.match(/(.+)-(.+)-(.+)/)) {
        return null
      }

      return theme
    },

    botStyle() {
      const style = {}

      const theme = (this.bot || {}).theme || null

      if (!! theme && theme.match(/(.+)-(.+)-(.+)/)) {
        const parts = theme.match(/(.+)-(.+)-(.+)/)
        style['--primary-h'] = parts[1]
        style['--primary-s'] = parts[2]
        style['--primary-l'] = parts[3]
      }

      return style
    }
  },

  watch: {
    bot() {
      if (! this.bot) {
        return
      }

      this.$i18n.locale = this.bot.locale
    }
  },

  mounted() {
    this.initialize()
  },

  methods: {
    initialize() {
      window.$eventHubChat = new Vue()

      Client.users.get().then(response => {
        if (response.status !== 200) {
          return
        }

        const chatUser = response.data

        // Establish socket connection

        window.Pusher = Pusher

        window.Echo = new Echo({
          broadcaster: 'pusher',
          key: options.BROADCAST_KEY,
          cluster: '',
          wsHost: 'realtime-pusher.ably.io',
          wsPort: 443,
          disableStats: true,
          encrypted: true,
          namespace: '',

          authorizer: (channel) => ({
            authorize: (socketId, callback) => {
              axios.post(`${options.API_URL}/broadcasting/auth?key=${chatUser.unique_id}`, {
                socket_id: socketId,
                channel_name: channel.name
              }).then(response => {
                callback(null, response.data);
              }).catch(error => {
                callback(error);
              });
            }
          })
        })

        // Set user
        this.chatUser = chatUser

        Cookies.set(COOKIE_ID, chatUser.unique_id)
      })
    }
  },

  components: {
    ChatWindow
  }
}
</script>
